import React from 'react';
import './Profile.css';

export default function Profile({ username, onLogout }) {
    return (
        <div className="profile-container">
            <h1>Welcome, {username}!</h1>
            <p>This is your profile page. You are successfully logged in.</p>
            <button onClick={onLogout}>Log Out</button>
        </div>
    );
}
