// src/components/About/About.js
import React from 'react';
import Header from '../Header';
import './About.css';

import aboutImage from '../about1.jpg';


export default function About({ loggedIn, username, onLogout }) {
    return (
        <div>
            {/* Reusable Header Component */}
           

            {/* About Us Section */}
            <div className="about-container">
                <h1>About Us</h1>
                <p>
                    Welcome to Legal Text Aggregator - Your one-stop solution for effortlessly referencing and understanding legal texts and articles.
                    We understand the complexity and time-consuming nature of navigating through legal documents, which is why we've developed a 
                    cutting-edge service to streamline the process for you.
                </p>

             {/* About Us Image */}
             <div className="about-image-wrapper">
                    <img src={aboutImage} alt="Legal Text Aggregator" className="about-image" />
                </div>

                <h2>Our Mission</h2>
                <p>
                    Our platform is designed to simplify the task of referencing specific sections and articles within bills, statutes, and legal documents.
                    With just a simple input of a bill’s amendment or modification, we provide you with comprehensive access to the relevant sections, 
                    saving you valuable time and effort.
                </p>

                <h2>Who We Serve</h2>
                <p>
                    Whether you're a legal professional, a researcher, a student, or simply an individual seeking clarity on legal matters, our platform 
                    caters to your needs with precision and efficiency. By providing access to the precise information you require, we empower you to navigate 
                    the complexities of legal texts with ease.
                </p>

                <h2>Explore Key Features</h2>
                <p>
                    The platform streamlines the arduous task of searching and cross-referencing legal documents by seamlessly integrating bill modifications. 
                    This allows users, including legal professionals, researchers, and students, to quickly access relevant sections and articles, thus optimizing workflows.
                </p>
                <p>
                    Your Legal Text Aggregator offers a thorough overview of modified sections, including all pertinent amendments and updates, recognizing the 
                    necessity of comprehensive legal research. This approach ensures that users have access to every detail required to comprehend legislative changes, 
                    enabling them to make confident and informed decisions.
                </p>
                <p>
                    Experience the transformative effects of these features on your legal research endeavors, as they simplify the complexities associated with 
                    navigating legal texts and boost productivity.
                </p>
            </div>
        </div>
    );
}