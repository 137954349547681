// src/AmendmentList.js
import React, { useState, useEffect } from 'react';
import './AmendmentList.css'; // Import the CSS file for styling

const AmendmentList = ({ theme }) => {  // Accept theme prop
  const [amendments, setAmendments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const populateInfo = (info) => {
    console.log(info);
    
  }

  useEffect(() => {
    fetch(
      'https://api.congress.gov/v3/amendment?api_key=CyzaP2DlID6SGJMnbHhz506MQX6fpzEEGoQrEeOT&format=xml&fromDateTime=1970-01-01T00:00:00Z&toDateTime=2021-12-31T23:59:59Z'
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((xmlString) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
        const amendmentsContainer = xmlDoc.getElementsByTagName('amendments')[0];
        if (!amendmentsContainer) {
          throw new Error('No <amendments> element found in the XML');
        }
        const amendmentNodes = amendmentsContainer.getElementsByTagName('amendment');

        const amendmentsArray = Array.from(amendmentNodes).map((amendmentNode) => {
          const getTextContent = (tagName) => {
            const node = amendmentNode.getElementsByTagName(tagName)[0];
            return node ? node.textContent.trim() : 'Unknown';
          };

          return {
            number: getTextContent('number'),
            congress: getTextContent('congress'),
            type: getTextContent('type'),
          };
        });

        setAmendments(amendmentsArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching or parsing XML:', err);
        setError(err);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className={`amendment-list-container ${theme}`}>Loading...</div>;
  if (error) return <div className={`amendment-list-container ${theme}`}>Error: {error.message}</div>;

  return (
    <div className={`amendment-list-container ${theme}`}>
      <h2>Amendments</h2>
      <ul>
        {amendments.map((amendment, index) => (
          <li key={index} onClick={() => populateInfo([amendment.congress, amendment.type, amendment.number])}>
            <div className="amendment-info">
              <span>
                <strong>Congress:</strong> {amendment.congress}
              </span>
              <span>
                <strong>Type:</strong> {amendment.type}
              </span>
              <span>
                <strong>Number:</strong> {amendment.number}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AmendmentList;
