import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import MainComponent from './MainComponent/MainComponent';
import About from './About/About';
import Contact from './Contact/Contact';
import Pricing from './Pricing/Pricing';
import Login from './Login';
import Profile from './Profile';
import Header from './Header';

function App() {
    // Login/Logout state
    const [loggedIn, setLoggedIn] = useState(false);
    const username = "Stephen"; // Mock username example

    // Theme state
    const [theme, setTheme] = useState('light');

    // Login and Logout Handlers
    const handleLogin = () => setLoggedIn(true);
    const handleLogout = () => setLoggedIn(false);

    // Toggle theme function
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
    };

    // Apply the theme to the body class
    useEffect(() => {
        document.body.className = theme === 'dark' ? '' : 'light-mode';
    }, [theme]);

    return (
        <Router>
            {/* Pass theme, toggleTheme, and login state to Header */}
            <Header
                loggedIn={loggedIn}
                username={username}
                toggleTheme={toggleTheme}
                theme={theme}
            />
            <Routes>
                {/* Pass theme and other necessary props to pages */}
                <Route
                    path="/"
                    element={<MainComponent theme={theme} />}
                />
                <Route
                    path="/about"
                    element={<About theme={theme} />}
                />
                <Route
                    path="/contact"
                    element={<Contact theme={theme} />}
                />
                <Route
                    path="/pricing"
                    element={<Pricing theme={theme} />}
                />
                <Route
                    path="/login"
                    element={<Login theme={theme} onLogin={handleLogin} />}
                />
                <Route
                    path="/profile"
                    element={<Profile theme={theme} username={username} onLogout={handleLogout} />}
                />
            </Routes>
        </Router>
    );
}

export default App;
