// src/components/Contact/Contact.js
import React, { useState } from 'react';
import Header from '../Header';
import './Contact.css';

export default function Contact({ loggedIn, username, onLogout }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Form submitted: ' + JSON.stringify(formData));
    };

    return (
        <div>
            {/* Reusable Header Component */}
           

            {/* Contact Us Section */}
            <div className="contact-container">
                <h1>Contact Us</h1>
                <p>
                    Email: <a href="mailto:contact@legalaggregator.com">contact@legalaggregator.com</a>
                </p>
                <p>
                    Address: 1151 S Forest Ave, Tempe, AZ 85287
                </p>

                {/* Contact Form */}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name (required):</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email (required):</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                    </div>

                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}
