// src/BillList.js
import React, { useState, useEffect } from 'react';
import './BillList.css'; // Import the CSS file for styling

const BillList = ({ theme }) => {  // Accept theme prop
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const populateInfo = (info) => {
    console.log(info);
    
  }

  useEffect(() => {
    fetch(
      'https://api.congress.gov/v3/bill?api_key=CyzaP2DlID6SGJMnbHhz506MQX6fpzEEGoQrEeOT&format=xml&fromDateTime=1970-01-01T00:00:00Z&toDateTime=2021-12-31T23:59:59Z'
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((xmlString) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
        const billNodes = xmlDoc.getElementsByTagName('bill');

        const billsArray = Array.from(billNodes).map((billNode) => {
          const getTextContent = (tagName) => {
            const node = billNode.getElementsByTagName(tagName)[0];
            return node ? node.textContent.trim() : 'Unknown';
          };

          return {
            congress: getTextContent('congress'),
            type: getTextContent('type'),
            number: getTextContent('number'),
            date: getTextContent('actionDate'),
          };
        });

        setBills(billsArray);
        console.log(billsArray);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching or parsing XML:', err);
        setError(err);
        setLoading(false);
      });
  }, []);

  if (loading)
    return <div className={`bill-list-container ${theme}`}>Loading...</div>;
  if (error)
    return <div className={`bill-list-container ${theme}`}>Error: {error.message}</div>;

  return (
    <div className={`bill-list-container ${theme}`}>
      <h2>Bills</h2>
      <ul>
        {bills.map((bill, index) => (
          <li key={index} onClick={() => {populateInfo([bill.congress, bill.type, bill.number])}}>
            <div className="bill-info">
              <span>
                <strong>Congress:</strong> {bill.congress}
              </span>
              <span>
                <strong>Type:</strong> {bill.type}
              </span>
              <span>
                <strong>Number:</strong> {bill.number}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BillList;
