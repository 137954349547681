// src/components/Pricing/Pricing.js
import React from 'react';
import Header from '../Header';
import './Pricing.css';

export default function Pricing({ loggedIn, username, onLogout }) {
    return (
        <div>
            {/* Reusable Header Component */}
           

            {/* Pricing Section */}
            <div className="pricing-container">
                <h1>Pricing</h1>
                <p>
                    Whether you opt for our Basic or Premium plan, rest assured that we have tailored our offerings to suit your requirements.
                    Our Basic plan provides a streamlined solution for those seeking essential tools to navigate legal texts effortlessly.
                    Meanwhile, our Premium plan offers additional benefits for those looking to enhance their legal research experience.
                    No matter which plan you choose, our goal is to empower you with the necessary tools and support to meet your unique needs effectively.
                </p>

                <h2>Basic Plan</h2>
                <p>
                    Our Basic plan serves as an invaluable resource for individuals seeking simplified solutions to their legal referencing needs.
                    With its user-friendly interface and intuitive tools, customers can efficiently navigate through legal documents without hassle.
                    This plan is particularly beneficial for those who require quick access to relevant information without the complexities often associated
                    with legal research. By offering essential functionalities, the Basic plan ensures that users can address their legal queries with ease and confidence.
                </p>

                <h2>Premium Plan</h2>
                <p>
                    Elevating the legal research experience, our Premium plan provides an array of advanced features designed to streamline workflow and enhance productivity.
                    Tailored for professionals and researchers requiring comprehensive solutions, this plan offers added benefits such as enhanced data management and
                    real-time updates. Customers opting for the Premium plan gain access to a suite of tools aimed at simplifying complex legal matters and staying
                    ahead of legislative changes. With its robust features and tailored support, the Premium plan empowers users to make informed decisions and achieve
                    their objectives with efficiency.
                </p>

                {/* Pricing Table */}
                <div className="pricing-table">
                    <div className="pricing-card">
                        <h3>Basic Plan</h3>
                        <p className="price">$0/yr</p>
                        <ul>
                            <li>Search for and display a bill or amendment</li>
                            <li>Apply an amendment and display the revised bill</li>
                            <li>Convert into layman’s terms for ease of reading and understanding</li>
                        </ul>
                        <button>Start Basic Plan</button>
                    </div>

                    <div className="pricing-card">
                        <h3>Premium Plan</h3>
                        <p className="price">$50/yr</p>
                        <ul>
                            <li>Includes all Basic features</li>
                            <li>Save data to user profile</li>
                            <li>Receive alerts when saved bills receive new amendments</li>
                            <li>Receive notifications regarding related bills</li>
                        </ul>
                        <button>Start Premium Plan</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
