// src/Header.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

export default function Header({ loggedIn, username, toggleTheme, theme }) {
    return (
        <header>
            <div className="header-left">
                <div className="logo-container">
                    {/* Main Heading */}
                    <h1 className="main-heading">Legal-Ese</h1>
                    
                    {/* Subheading (Stacked Underneath) */}
                    <h3 className="subheading">Legal Text Aggregator</h3>
                </div>
                
                {/* Theme toggle switch */}
                <div className="theme-toggle">
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            onChange={toggleTheme} 
                            checked={theme === 'light'} 
                        />
                        <span className="slider"></span>
                    </label>
                </div>
            </div>
            <nav className="nav">
                <NavLink to="/" end className="nav-link">Home</NavLink>
                <NavLink to="/about" className="nav-link">About</NavLink>
                <NavLink to="/pricing" className="nav-link">Pricing</NavLink>
                <NavLink to="/contact" className="nav-link">Contact</NavLink>
                {/* Login/Profile link */}
                {loggedIn ? (
                    <NavLink to="/profile" className="nav-link">{username}</NavLink>
                ) : (
                    <NavLink to="/login" className="nav-link">Login</NavLink>
                )}
            </nav>
        </header>
    );
}
