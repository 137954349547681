import React, { useState, useRef, useEffect } from 'react';
import './MainComponent.css';
import { NavLink } from 'react-router-dom';
import BillList from '../components/BillList/BillList';
import AmendmentList from '../components/AmendmentList/AmendmentList';

export default function MainComponent({ theme }) {
    const [selectedTab, setSelectedTab] = useState('amendment');
    const laymensText = useRef(null);
    const resultText = useRef(null);
    const typeOfAmendment = useRef(null);
    const amendmentNumber = useRef(null);
    const congressNumber = useRef(null);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);

        while (typeOfAmendment.current.options.length > 0) {
            typeOfAmendment.current.remove(0);
        }

        switch (tab) {
            case 'amendment':
                typeOfAmendment.current.add(new Option('Senate', 'samdt'));
                typeOfAmendment.current.add(new Option('House', 'hamdt'));
                typeOfAmendment.current.value = 'samdt';
                break;
            case 'bill':
                typeOfAmendment.current.add(new Option('Senate', 's'));
                typeOfAmendment.current.add(new Option('House', 'hr'));
                typeOfAmendment.current.add(new Option('Senate Joint Resolution', 'sjres'));
                typeOfAmendment.current.add(new Option('House Joint Resolution', 'hjres'));
                typeOfAmendment.current.add(new Option('Senate Concurrent Resolution', 'sconres'));
                typeOfAmendment.current.add(new Option('House Concurrent Resolution', 'hconres'));
                typeOfAmendment.current.add(new Option('Senate Resolution', 'sres'));
                typeOfAmendment.current.add(new Option('House Resolution', 'hres'));
                typeOfAmendment.current.value = 's';
                break;
            case 'amendmentInspect':
                typeOfAmendment.current.add(new Option('Senate', 'samdt'));
                typeOfAmendment.current.add(new Option('House', 'hamdt'));
                typeOfAmendment.current.value = 'samdt';
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        handleTabClick('amendment');
    }, []);

    const fetchBillText = async () => {
        try {
            const optionsObject = {
                docNumber: amendmentNumber.current.value,
                typeOfAction: selectedTab,
                docType: typeOfAmendment.current.value,
                congressNumber: congressNumber.current.value,
            };
            console.log(optionsObject);

            const response = await fetch('http://127.0.0.1:8000/congress', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(optionsObject),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data from server');
            }

            const data = await response.json();
            console.log(data);
            laymensText.current.value = `Layman's Terms: ${data.laymanExplanation}`;
            resultText.current.value = data[selectedTab]?.text || 'No data available.';
        } catch (error) {
            console.error('Error fetching bill text:', error.message);
        }
    };

    return (
        <div className={`main-container ${theme}`}>
            <BillList theme={theme} />
            <div className="home-container">
                <h1 className={`header ${theme}`}>Analyze Legal Texts</h1>

                {/* Tabs Section */}
                <div className="tab-container">
                    <button
                        className={`${selectedTab === 'amendment' ? 'active' : ''} ${theme}`}
                        onClick={() => handleTabClick('amendment')}
                    >
                        Apply Amendment to Bill
                    </button>
                    <button
                        className={`${selectedTab === 'bill' ? 'active' : ''} ${theme}`}
                        onClick={() => handleTabClick('bill')}
                    >
                        Inspect Bill
                    </button>
                    <button
                        className={`${selectedTab === 'amendmentInspect' ? 'active' : ''} ${theme}`}
                        onClick={() => handleTabClick('amendmentInspect')}
                    >
                        Inspect Amendment
                    </button>
                </div>

                {/* Form Section */}
                <div className={`form-container ${theme}`}>
                    <div className="form-group">
                        <label className={`label ${theme}`} htmlFor="amendmentNumber">Amendment Number</label>
                        <input
                            type="text"
                            id="amendmentNumber"
                            placeholder="2137"
                            ref={amendmentNumber}
                            className={`input ${theme}`}
                        />
                    </div>
                    <div className="form-group">
                        <label className={`label ${theme}`} htmlFor="typeOfAmendment">Type of Amendment</label>
                        <select
                            id="typeOfAmendment"
                            ref={typeOfAmendment}
                            className={`select ${theme}`}
                        ></select>
                    </div>
                    <div className="form-group">
                        <label className={`label ${theme}`} htmlFor="congressNumber">Congress Number</label>
                        <input
                            type="text"
                            id="congressNumber"
                            placeholder="117"
                            ref={congressNumber}
                            className={`input ${theme}`}
                        />
                    </div>
                    <button
                        type="submit"
                        className={`inspect-button ${theme}`}
                        onClick={fetchBillText}
                    >
                        Inspect
                    </button>
                </div>

                {/* Subheader Section */}
                <h2 className={`subheader ${theme}`}>Results</h2>
                <div id="results-container">
                    <textarea
                        id="laymenText"
                        placeholder="Layman's text appears here"
                        ref={laymensText}
                        className={`textarea ${theme}`}
                    ></textarea>
                    <textarea
                        id="resultText"
                        placeholder="Result text appears here"
                        ref={resultText}
                        className={`textarea ${theme}`}
                    ></textarea>
                </div>
            </div>
            <AmendmentList theme={theme} />
        </div>
    );
}
